$(document).ready(function () {
    $('.protector').each (function () {
        var id = this.getAttribute ('data-id');
        $(this).change (function () {
            if (this.checked)
                $('#' + id).each (function () {
                    this.removeAttribute ('readonly')
                    this.dataset.value = this.value
                });
            else
                $('#' + id).each (function () {
                    this.setAttribute ('readonly', '1')
                    this.value = this.dataset.value
                })
        })
    })
});
