/**
 * toggle checkboxes
 */
$(function () {
    $('[data-toggle-checked]')
        .each(toggleCheckbox)
        .on('change', toggleCheckbox);

    function toggleCheckbox () {
        var $checkboxes = $(this);
        $checkboxes.each(function() {
            var $checkbox = $(this);
            if ($checkbox.is(':checked') && $checkbox.data('toggle-checked') != '') {
                $($checkbox.data('toggle-checked')).show();
            } else if ($checkbox.data('toggle-checked') != '') {
                $($checkbox.data('toggle-checked')).hide();
            }
            if (!$checkbox.is(':checked') && $checkbox.data('toggle-unchecked') != '') {
                $($checkbox.data('toggle-unchecked')).show();
            } else if ($checkbox.data('toggle-unchecked') != '') {
                $($checkbox.data('toggle-unchecked')).hide();
            }
        });
    }
});
