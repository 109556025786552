/**
 * datetime picker
 */
$(function () {
    var datetimePickerIcons = {
        time: 'fa fa-clock-o',
        date: 'fa fa-calendar-o',
        up: 'fa fa-arrow-up',
        down: 'fa fa-arrow-down',
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-crosshairs',
        clear: 'fa fa-trash-o',
        close: 'fa fa-times'
    };
    var dateFormat = 'L',
        timeFormat = 'LT';
    $('.input-group.datetime').datetimepicker({
        icons: datetimePickerIcons,
        format: dateFormat + ' ' + timeFormat
    });
    $('.input-group.date').datetimepicker({
        icons: datetimePickerIcons,
        format: dateFormat
    });
    $('.input-group.time').datetimepicker({
        icons: datetimePickerIcons,
        format: timeFormat
    });
});
