/**
 * default actions for ajax requests
 * to disable this behaviour you need to add `global: false` to $.ajax() parameters
 */

/**
 * automatically changes body class if ajax is in progress
 *
 * @param d
 */
var n = 0;
window.countAjax = function (d) {
    var cssClass = window.countAjaxCssClass || 'ajax-progress';

    n += d;
    $('body')[n > 0 ? 'addClass' : 'removeClass'](cssClass);
};

$(document).ajaxSend(function () {
    window.countAjax(1);
});

$(document).ajaxComplete(function () {
    window.countAjax(-1);
});

$(document).ajaxError(function (event, jqxhr, settings, thrownError) {
    if (jqxhr.responseText.length > 0) {
        var responseText = $.parseJSON(jqxhr.responseText);
        if (responseText.message) {
            alert(responseText.message);
        } else {
            alert(jqxhr.responseText);
        }
    } else if (jqxhr.statusText.length > 0) {
        alert(jqxhr.statusText);
    }
});
