IntlNumbers = function (locale) {
    var testNumber = Intl.NumberFormat(locale).format(1234.5)
    var matches = /^1(.*)234(.*)5$/.exec(testNumber)
    this.fractionSeparator = matches[2]
    this.thousandsSeparator = matches[1]
}
IntlNumbers.prototype = {
    'fractionSeparator': ',',
    'thousandsSeparator' : '.',

    'unformat': function (formated) {
        return formated.replaceAll(this.thousandsSeparator, '').replace(this.fractionSeparator, '.')
    },

    'parseInt': function (formated) {
        return parseInt(this.unformat(formated), 10)
    },

    'parseFloat': function (formated) {
        return parseFloat(this.unformat(formated))
    },

    'parseWeight': function (formated) {
        var suffix = /[a-zA-Z]*$/.exec(formated)[0] || 'kg'
        var factor = {
            't': 1000,
            'kg': 1,
            'g' : 0.001
        }[suffix] || 1
        return this.parseFloat(formated) * factor
    }
}
