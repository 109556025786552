/**
 * JQuery autocomplete plugin for places
 */
(function ($) {
    $.fn.placesAutocomplete = function (options) {
        var opts = $.extend({}, $.fn.placesAutocomplete.defaults, options);
        $.fn.placesAutocomplete.opts = opts;

        // do nothing, if length of selected elements is less or equal 0
        if (this.length <= 0) return false;

        return this.each(function () {
            var $form = $(this),
                $countryId = $form.find(opts.selectors.countryId);

            $.fn.placesAutocomplete.initSources($form);

            $form.find(opts.selectors.postalCode).typeahead({
                hint: true,
                highlight: true,
                minLength: 2
            }, {
                name: 'postalcode',
                display: 'postalCode',
                limit: opts.limit,
                templates: {
                    suggestion: $.fn.placesAutocomplete.getPlacesSuggestionTemplate
                },
                source: $.fn.placesAutocomplete.postalCodes
            }).on('typeahead:select', function (e, place) {
                $form.find(opts.selectors.city).typeahead('val', place.city);
                $form.find(opts.selectors.streetname).typeahead('val', '');
                $.fn.placesAutocomplete.updateStreetsSource(place.postalCode, $countryId.val());
            });

            $form.find(opts.selectors.city).typeahead({
                hint: true,
                highlight: true,
                minLength: 3
            }, {
                name: 'city',
                display: 'city',
                limit: opts.limit,
                templates: {
                    suggestion: $.fn.placesAutocomplete.getPlacesSuggestionTemplate
                },
                source: $.fn.placesAutocomplete.cities
            }).on('typeahead:select', function (e, place) {
                $form.find(opts.selectors.postalCode).typeahead('val', place.postalCode);
                $form.find(opts.selectors.streetname).typeahead('val', '');
                $.fn.placesAutocomplete.updateStreetsSource(place.postalCode, $countryId.val());
            });

            $form.find(opts.selectors.streetname).typeahead({
                hint: true,
                highlight: true,
                minLength: 3
            }, {
                name: 'street',
                display: 'street',
                limit: opts.limit,
                templates: {
                    suggestion: $.fn.placesAutocomplete.getStreetsSuggestionTemplate
                },
                source: $.fn.placesAutocomplete.streets
            }).on('typeahead:select', function (e, street) {
                $form.find(opts.selectors.postalCode).typeahead('val', street.place.postalCode);
                $form.find(opts.selectors.city).typeahead('val', street.place.city);
                $.fn.placesAutocomplete.updateStreetsSource(street.place.postalCode, $countryId.val());
            });

            $('.tt-input')
                .on('focus blur', function () {
                    $(this).data('changed', false);
                })
                .on('input', function () {
                    $(this).data('changed', true);
                })
                .on('typeahead:open', function () {
                    var $input = $(this);
                    if (!$input.data('changed')) {
                        $input.typeahead('close');
                    }

                });

            // re-init on country change
            $countryId.on('change', function () {
                $.fn.placesAutocomplete.clearSources($form);
                $.fn.placesAutocomplete.updatePlacesSource($form);
            });
        });
    };

    /**
     * default options
     *
     * @type {{}}
     */
    $.fn.placesAutocomplete.defaults = {
        'selectors': {
            'postalCode': '[name*=postalcode]',
            'city': '[name*=city]',
            'streetname': '[name*=streetname]',
            'countryId': '[name*=country_id]'
        },
        'urls': {
            'places': '/places/list.json',
            'streets': '/places/streets.json'
        },
        'limit': 10
    };

    /**
     * Initializes sources and load data
     */
    $.fn.placesAutocomplete.initSources = function ($form) {
        var opts = $.fn.placesAutocomplete.opts;
        $.fn.placesAutocomplete.cities = new Bloodhound({
            local: [],
            datumTokenizer: function (datum) {
                return Bloodhound.tokenizers.whitespace(datum.city);
            },
            queryTokenizer: Bloodhound.tokenizers.whitespace
        });
        $.fn.placesAutocomplete.postalCodes = new Bloodhound({
            local: [],
            datumTokenizer: function (datum) {
                return Bloodhound.tokenizers.whitespace(datum.postalCode);
            },
            queryTokenizer: Bloodhound.tokenizers.whitespace
        });
        $.fn.placesAutocomplete.streets = new Bloodhound({
            local: [],
            remote: {
                url: opts.urls.streets,
                prepare: function (query, settings) {
                    settings.data = {
                        street: query,
                        postalCode: $form.find(opts.selectors.postalCode).val(),
                        city: $form.find(opts.selectors.city).val(),
                        countryId: $form.find(opts.selectors.countryId).val()
                    };
                    return settings;
                },
                transform: function (response) {
                    return response.streets;
                }
            },
            datumTokenizer: function (datum) {
                return Bloodhound.tokenizers.whitespace(datum.street);
            },
            queryTokenizer: Bloodhound.tokenizers.whitespace
        });

        $.fn.placesAutocomplete.updatePlacesSource($form);
    };

    /**
     * Clear all sources
     */
    $.fn.placesAutocomplete.clearSources = function ($form) {
        $.fn.placesAutocomplete.cities.clear();
        $.fn.placesAutocomplete.cities.local = [];
        $.fn.placesAutocomplete.cities.initialize(true);

        $.fn.placesAutocomplete.postalCodes.clear();
        $.fn.placesAutocomplete.postalCodes.local = [];
        $.fn.placesAutocomplete.postalCodes.initialize(true);

        $.fn.placesAutocomplete.streets.clear();
        $.fn.placesAutocomplete.streets.local = [];
        $.fn.placesAutocomplete.streets.initialize(true);

        $.fn.placesAutocomplete.refreshSuggestions($form);
    };

    /**
     * Updates data and re-initializes the places sources
     */
    $.fn.placesAutocomplete.updatePlacesSource = function ($form) {
        var opts = $.fn.placesAutocomplete.opts;
        $.ajax({
            url: opts.urls.places,
            data: {
                countryId: $form.find(opts.selectors.countryId).val()
            },
            success: function (response) {
                var places = response.places;

                $.fn.placesAutocomplete.cities.clear();
                $.fn.placesAutocomplete.cities.local = places;
                $.fn.placesAutocomplete.cities.initialize(true);

                $.fn.placesAutocomplete.postalCodes.clear();
                $.fn.placesAutocomplete.postalCodes.local = places;
                $.fn.placesAutocomplete.postalCodes.initialize(true);

                $.fn.placesAutocomplete.refreshSuggestions($form);
            }
        });
    };

    /**
     * Updates data and re-initializes the street sources
     */
    $.fn.placesAutocomplete.updateStreetsSource = function (postalCode, countryId) {
        var opts = $.fn.placesAutocomplete.opts;
        $.ajax({
            url: opts.urls.streets,
            data: {
                postalCode: postalCode,
                countryId: countryId
            },
            success: function (response) {
                var streets = response.streets;

                $.fn.placesAutocomplete.streets.clear();
                $.fn.placesAutocomplete.streets.local = streets;
                $.fn.placesAutocomplete.streets.initialize(true);
            }
        });
    };

    /**
     * refresh suggestions by changing values
     *
     * @param $form
     */
    $.fn.placesAutocomplete.refreshSuggestions = function ($form) {
        var opts = $.fn.placesAutocomplete.opts,
            city = $form.find(opts.selectors.city).val(),
            postalCode = $form.find(opts.selectors.postalCode).val(),
            streetName = $form.find(opts.selectors.streetname).val();

        $form.find(opts.selectors.city).typeahead('val', '').typeahead('val', city);
        $form.find(opts.selectors.postalCode).typeahead('val', '').typeahead('val', postalCode);
        $form.find(opts.selectors.streetname).typeahead('val', '').typeahead('val', streetName);
    };

    /**
     * Returns suggestion template with postal code and city
     *
     * @param place
     * @returns {string}
     */
    $.fn.placesAutocomplete.getPlacesSuggestionTemplate = function (place) {
        var placeStr = place.postalCode + ' ' + place.city;
        if (place.hint) {
            placeStr = placeStr + ' ' + place.hint;
        }
        return '<div>' + placeStr + '</div>';
    };

    /**
     * Returns suggestion template with street name, postal code and city
     *
     * @param street
     * @returns {string}
     */
    $.fn.placesAutocomplete.getStreetsSuggestionTemplate = function (street) {
        var placeStr = street.place.postalCode + ' ' + street.place.city;
        if (street.place.hint) {
            placeStr = placeStr + ' ' + street.place.hint;
        }
        var streetStr = street.street + ' <small>' + placeStr + '</small>';
        return '<div>' + streetStr + '</div>';
    };
}(jQuery));
