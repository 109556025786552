$(function () {
    $('a[data-pick-record]').on('click', function (e) {
        var $link = $(this),
            $icon = $link.find('.fa');

        e.preventDefault();

        $.ajax({
            url: $link.data('pick-record-url'),
            beforeSend: function () {
                $icon.addClass('disabled');
            },
            complete: function () {
                $icon.removeClass('disabled');
            },
            success: function (result) {
                if (result.success) {
                    $icon.removeClass('fa-check-circle-o fa-circle-o');
                    $icon.addClass(result.picked ? 'fa-check-circle-o' : 'fa-circle-o');
                }
            }
        })
    });
});
