/**
 * use jquery plugin multiselect from lou (https://github.com/lou/multi-select)
 * for searchable inputs, we use additionally the quicksearch library (https://deuxhuithuit.github.io/quicksearch/)
 */
$(function () {
    var multiSelectTexts = window.multiSelectTexts || {
            'available': 'Verfügbar',
            'selected': 'Ausgewählt',
            'searchPlaceholder': 'Durchsuchen'
        };

    $('select[multiple]').multiSelect({
        selectableHeader: '<div class="ms-selectable-header">' + multiSelectTexts.available + '</div>' +
        '<div class="ms-selectable-search"><input type="text" class="form-control" placeholder="' + multiSelectTexts.searchPlaceholder + ' …" /></div>',
        selectionHeader: '<div class="ms-selectable-header">' + multiSelectTexts.selected + '</div>' +
        '<div class="ms-selectable-search"><input type="text" class="form-control" placeholder="' + multiSelectTexts.searchPlaceholder + ' …" /></div>',
        afterInit: function (ms) {
            var that = this,
                selectableSearchString = '#' + that.$container.attr('id') + ' .ms-elem-selectable:not(.ms-selected)',
                selectionSearchString = '#' + that.$container.attr('id') + ' .ms-elem-selection.ms-selected';

            that.$selectableSearch = that.$selectableUl.prev().find('> input');
            that.$selectionSearch = that.$selectionUl.prev().find('> input');

            that.qs1 = that.$selectableSearch.quicksearch(selectableSearchString)
                .on('keydown', function (e) {
                    if (e.which === 40) {
                        that.$selectableUl.focus();
                        return false;
                    }
                });

            that.qs2 = that.$selectionSearch.quicksearch(selectionSearchString)
                .on('keydown', function (e) {
                    if (e.which == 40) {
                        that.$selectionUl.focus();
                        return false;
                    }
                });
        },
        afterSelect: function () {
            this.qs1.cache();
            this.qs2.cache();
        },
        afterDeselect: function () {
            this.qs1.cache();
            this.qs2.cache();
        }
    });
});
