/**
 * JQuery autocomplete plugin for specifications
 */
(function ($) {
    $.fn.specsAutocomplete = function (options) {
        var opts = $.extend({}, $.fn.specsAutocomplete.defaults, options);
        $.fn.specsAutocomplete.opts = opts;

        // do nothing, if length of selected elements is less or equal 0
        if (this.length <= 0) return false;

        return this.each(function () {
            var $form = $(this);

            $.fn.specsAutocomplete.initSources($form);

            $form.find(opts.selectors.specKey).typeahead({
                hint: true,
                highlight: true,
                minLength: 0
            }, {
                name: 'specKey',
                display: 'specKey',
                limit: opts.specKeyLimit,
                templates: {
                    suggestion: $.fn.specsAutocomplete.getSpecKeySuggestionTemplate
                },
                source: $.fn.specsAutocomplete.specKeys
            }).on('typeahead:select', function (e, specKey) {
                $form.find(opts.selectors.specKey).typeahead('val', specKey.name);
                $form.find(opts.selectors.specValue).typeahead('val', '');
                $.fn.specsAutocomplete.updateSpecValueSource($form);
            });

            $form.find(opts.selectors.specValue).typeahead({
                hint: true,
                highlight: true,
                minLength: 0
            }, {
                name: 'specValue',
                display: 'specValue',
                limit: opts.specValueLimit,
                templates: {
                    suggestion: $.fn.specsAutocomplete.getSpecValueSuggestionTemplate
                },
                source: $.fn.specsAutocomplete.specValues
            }).on('typeahead:select', function (e, specValue) {
                $form.find(opts.selectors.specKey).typeahead('val', specValue.spec_key.name);
                $form.find(opts.selectors.specValue).typeahead('val', specValue.name);
            });
        });
    };

    /**
     * default options
     *
     * @type {{}}
     */
    $.fn.specsAutocomplete.defaults = {
        'selectors': {
            'specKey': '[name*=spec_key]',
            'specValue': '[name*=spec_value]',
        },
        'urls': {
            'specKeys': '/spec-keys/list.json',
            'specValues': '/spec-values/list.json',
        },
        'specKeyLimit': 100,
        'specValueLimit': 10
    };

    /**
     * Initializes sources and load data
     */
    $.fn.specsAutocomplete.initSources = function ($form) {
        var opts = $.fn.specsAutocomplete.opts;
        $.fn.specsAutocomplete.specKeys = new Bloodhound({
            local: [],
            remote: {
                url: opts.urls.specKeys,
                prepare: function (query, settings) {
                    settings.data = {
                        value: query
                    };
                    return settings;
                },
                transform: function (response) {
                    return response.specKeys;
                }
            },
            datumTokenizer: function (datum) {
                return Bloodhound.tokenizers.whitespace(datum.name);
            },
            queryTokenizer: Bloodhound.tokenizers.whitespace
        });
        $.fn.specsAutocomplete.specValues = new Bloodhound({
            local: [],
            remote: {
                url: opts.urls.specValues,
                prepare: function (query, settings) {
                    settings.data = {
                        value: query,
                        key: $form.find(opts.selectors.specKey).val()
                    };
                    return settings;
                },
                transform: function (response) {
                    return response.specValues;
                }
            },
            datumTokenizer: function (datum) {
                return Bloodhound.tokenizers.whitespace(datum.name);
            },
            queryTokenizer: Bloodhound.tokenizers.whitespace
        });

        $.fn.specsAutocomplete.updateSpecKeysSource($form);
    };

    /**
     * Clear all sources
     */
    $.fn.specsAutocomplete.clearSources = function ($form) {
        $.fn.specsAutocomplete.specKeys.clear();
        $.fn.specsAutocomplete.specKeys.local = [];
        $.fn.specsAutocomplete.specKeys.initialize(true);

        $.fn.specsAutocomplete.specValues.clear();
        $.fn.specsAutocomplete.specValues.local = [];
        $.fn.specsAutocomplete.specValues.initialize(true);

        $.fn.specsAutocomplete.refreshSuggestions($form);
    };

    /**
     * Updates data and re-initializes the specification key source
     */
    $.fn.specsAutocomplete.updateSpecKeysSource = function ($form) {
        var opts = $.fn.specsAutocomplete.opts;
        $.ajax({
            url: opts.urls.specKeys,
            success: function (response) {
                var specKeys = response.specKeys;

                $.fn.specsAutocomplete.specKeys.clear();
                $.fn.specsAutocomplete.specKeys.local = specKeys;
                $.fn.specsAutocomplete.specKeys.initialize(true);

                $.fn.specsAutocomplete.refreshSuggestions($form);
            }
        });
    };

    /**
     * Updates data and re-initializes the specification value source
     */
    $.fn.specsAutocomplete.updateSpecValueSource = function ($form) {
        var opts = $.fn.specsAutocomplete.opts;
        $.ajax({
            url: opts.urls.specValues,
            data: {
                key: $form.find(opts.selectors.specKey).val()
            },
            success: function (response) {
                var specValues = response.specValues;

                $.fn.specsAutocomplete.specValues.clear();
                $.fn.specsAutocomplete.specValues.local = specValues;
                $.fn.specsAutocomplete.specValues.initialize(true);

                $.fn.specsAutocomplete.refreshSuggestions($form);
            }
        });
    };

    /**
     * refresh suggestions by changing values
     *
     * @param $form
     */
    $.fn.specsAutocomplete.refreshSuggestions = function ($form) {
        var opts = $.fn.specsAutocomplete.opts,
            specKey = $form.find(opts.selectors.specKey).val(),
            specValue = $form.find(opts.selectors.specValue).val();

        $form.find(opts.selectors.specKey).typeahead('val', '').typeahead('val', specKey);
        $form.find(opts.selectors.specValue).typeahead('val', '').typeahead('val', specValue);
    };

    /**
     * Returns suggestion template for specification key
     *
     * @param specKey
     * @returns {string}
     */
    $.fn.specsAutocomplete.getSpecKeySuggestionTemplate = function (specKey) {
        return '<div>' + specKey.name + '</div>';
    };

    /**
     * Returns suggestion template for specification value
     *
     * @param specValue
     * @returns {string}
     */
    $.fn.specsAutocomplete.getSpecValueSuggestionTemplate = function (specValue) {
        return '<div>' + specValue.name + '</div>';
    };
}(jQuery));
